import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

const AContainer: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <div className={styles.admin_container}>
      {children}
    </div>
  );
};

export default AContainer;
