import { UserActionType, UserStateType } from './IUser';
import { IUser } from '../../types/entity/User';

const handlers = {
  // Тип недвижимости
  ['ADD_USER']: (state: UserStateType, payload: unknown) => {
    const payloadData = payload as IUser;

    return {
      ...state,
      user: payloadData,
    };
  },

  DEFAULT: (state: UserStateType) => state,
};

const UserReducer = (state: UserStateType, action: UserActionType): UserStateType => {
  const handler = handlers[action.type] || handlers.DEFAULT;

  return handler(state, action.payload);
};

export default UserReducer;
