import React, { FC } from 'react';
import { IErrorProps } from './IError';
import Error401 from './Error401';
import Error403 from './Error403';
import Error404 from './Error404';

const Error: FC<IErrorProps> = ({ code }): JSX.Element => {
  return (
    <div>
      {code === 401 && <Error401 />}
      {code === 403 && <Error403 />}
      {code === 404 && <Error404 />}
    </div>
  );
};

export default Error;
