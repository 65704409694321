import React, { FC } from 'react';
import styles from './styles.module.scss';

const Ellipse: FC = (): JSX.Element => {
  return (
    <div>
      <div className={`${styles.ellipse} ${styles.ellipse__purple}`} />
      <div className={`${styles.ellipse} ${styles.ellipse__yellow}`} />
    </div>
  );
};

export default Ellipse;
