import { FC, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import UserContext from '../../context/user/UserContext';
import { UserContextType } from '../../context/user/IUser';

const PrivateRoutes: FC = () => {
  const location = useLocation();
  const { getUser } = useContext(UserContext) as UserContextType;
  const user = getUser();

  if (!user) return <Navigate to='/login' replace state={{ from: location }} />;
  else if (location.pathname.startsWith('/admin') && user?.role !== 'admin')
    return <Navigate to='/login' replace state={{ from: location }} />;

  return <Outlet />;
};

export default PrivateRoutes;
