import React, { FC } from 'react';
import classNames from 'classnames';
import { IButtonProps } from './IButtonProps';
import styles from './styles.module.scss';

const Button: FC<IButtonProps<unknown>> = ({ children, nom = 'index', clickAction }): JSX.Element => {
  const onClickHandler = (): void => {
    if (typeof clickAction === 'function') clickAction();
  };

  return (
      <button
        className={classNames(styles.button, styles[`button__${nom}`])}
        onClick={onClickHandler}
      >
        {children}
      </button>
  );
};

export default Button;
