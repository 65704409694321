import { FC, PropsWithChildren, useReducer } from 'react';
import { UserStateType } from './IUser';
import UserReducer from './UserReducer';
import { IUser } from '../../types/entity/User';
import { UserTypes } from './UserTypes';
import UserContext from './UserContext';

const UserState: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const userState: UserStateType = {
    user: null,
  };
  const [state, dispatch] = useReducer(UserReducer, userState);

  const addUser = (user: IUser): void => {
    localStorage.setItem('user', JSON.stringify(user));

    dispatch({ type: UserTypes.ADD_USER, payload: user });
  };

  const getUser = (): IUser | null => {
    const user = localStorage.getItem('user');

    if (user) return JSON.parse(user);

    return null;
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        addUser,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
