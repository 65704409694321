import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import Header from '../../Header';

const ErrorLayout: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <>
      <Header />
      <main>
        <div className={styles.error}>
          {children}
        </div>
      </main>
    </>
  );
};

export default ErrorLayout;
