import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ICheckboxInputProps } from './ICheckboxInput';
import classNames from 'classnames';

const CheckboxInput: FC<ICheckboxInputProps> = ({ refElement, id, label, value }): JSX.Element => {
  const [isChecked, setIsChecked] = useState(value);

  const onChangeHandler = (): void => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (value !== isChecked) setIsChecked(value);
  }, [value]);

  return (
    <div className={styles.input}>
      <div className={styles.input__group}>
        <label
          htmlFor={id}
          className={classNames(styles.input__checkbox, {
            [styles['input__checkbox--checked']]: isChecked,
          })}
        />
        <label htmlFor={id} className={styles.input__label}>
          {label}
        </label>
        <input
          ref={refElement}
          id={id}
          type='checkbox'
          checked={isChecked}
          onChange={onChangeHandler}
          className={styles.input__item}
        />
      </div>
    </div>
  );
};
export default CheckboxInput;
