import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

const Descr: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <p className={styles.descr}>
      {children}
    </p>
  );
};

export default Descr;
