import React, { FC, PropsWithChildren } from 'react';
import Header from '../../Header';
import { useLocation } from 'react-router-dom';
import HeaderFront from '../../HeaderFront';

const MainLayout: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname === '/admin/login' || pathname === '/admin/members' ||
      pathname === '/admin/settings' || pathname === '/admin/winners' ||
      pathname === '/404'
        ? <Header />
        : <HeaderFront />
      }
      <main className="main">
        {children}
      </main>
    </>
  );
};

export default MainLayout;
