import React, { FC, ReactNode, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ITextInputProps } from './ITextInput';
import InputMask, { Props } from 'react-input-mask';

type TInputMaskCorrect = Omit<Props, 'children'> & { children?: () => JSX. Element };
const InputMaskCorrect: FC<TInputMaskCorrect> = ( { children, ...props }) => {
  const child = children as ReactNode;
  return <InputMask children={child} {...props} />;
};

const TextInput: FC<ITextInputProps> = ({
  refElement,
  id,
  label,
  type,
  value,
  error,
  onChangeValidation,
  placeholder,
}): JSX.Element => {
  const [inputError, setInputError] = useState<string>('');

  // Валидация при вводе
  const onChangeHandler = (): void => {
    if (typeof onChangeValidation === 'function') {
      const currentValue = refElement.current !== null ? refElement.current.value : '';
      const errorString = onChangeValidation(currentValue);

      if (errorString.length > 0) setInputError(errorString);
      else setInputError('');
    }
  };

  useEffect(() => {
    if (error !== undefined && error.length > 0) setInputError(error);
  }, [error]);

  return (
    <div className={styles.input}>
      <div className={styles.input__group}>
        <label htmlFor={id} className={styles.input__label}>
          {label}
        </label>
        {type === 'tel'
          ? (
            <InputMaskCorrect
              mask="+7(999)999-99-99"
              defaultValue={value}
              onChange={onChangeHandler}
            >
              {() => (
                <input
                  ref={refElement}
                  id={id}
                  type={type}
                  className={styles.input__item}
                  placeholder={placeholder}
                />
              )}
            </InputMaskCorrect>
          )
          : <input
              ref={refElement}
              id={id}
              type={type}
              className={styles.input__item}
              defaultValue={value}
              onChange={onChangeHandler}
              placeholder={placeholder}
            />
        }
      </div>
      {inputError.length > 0 && <small className={styles.input__error}>{inputError}</small>}
    </div>
  );
};
export default TextInput;
