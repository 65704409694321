import React, { FC } from 'react';
import AppRoutes from './components/AppRoutes';
import 'react-quill/dist/quill.snow.css';
import './global.module.scss';

const App: FC = (): JSX.Element => {
  return <AppRoutes />;
};

export default App;
