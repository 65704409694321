import React, { FC, useContext, useRef } from 'react';
import Container from '../../components/ui/Container';
import Ellipse from '../../components/ui/Ellipses';
import styles from './styles.module.scss';
import Button from '../../components/ui/Buttons';
import TextInput from '../../components/input/TextInput';
import UserContext from '../../context/user/UserContext';
import { UserContextType } from '../../context/user/IUser';
import { useLocation, useNavigate } from 'react-router-dom';

const Login: FC = (): JSX.Element => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const { addUser } = useContext(UserContext) as UserContextType;
  const location = useLocation();
  const navigate = useNavigate();

  const submitClickHandler = (): void => {
    const passwordValue = passwordRef.current ? passwordRef.current.value : '';

    if (passwordValue.length > 0) {
      if (passwordValue === '4321')
        addUser({
          role: 'user',
        });

      if (passwordValue === '43214321')
        addUser({
          role: 'admin',
        });

      const { from } = location.state || { from: { pathname: '/' } };
      navigate(from, { replace: true });
    }
  };

  return (
    <section>
      <Container>
        <div className={styles.form__index}>
          <TextInput refElement={passwordRef} id='password' label='' type='password' value='' placeholder='Пароль' />
          <div className={styles.form__button}>
            <Button nom='index' clickAction={submitClickHandler}>
              Вход
            </Button>
          </div>
        </div>
      </Container>

      <Ellipse />
    </section>
  );
};

export default Login;
