import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Container from '../Container';
import { ISettings, ISettingsResponse } from '../../../types/entity/Settings';
import { getSettings } from '../../../utils/provider/admin/settings';

const HeaderFront: FC = (): JSX.Element => {
  const [settings, setSettings] = useState<ISettings | null>(null);

  useEffect(() => {
    getSettings<ISettingsResponse>()
      .then((response) => {
        setSettings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <header className={styles.header__front}>
        {settings?.header && <div dangerouslySetInnerHTML={{ __html: settings?.header }} />}
      </header>
    </Container>
  );
};

export default HeaderFront;
