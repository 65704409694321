import React, { FC, useEffect, useState } from 'react';
import Registration from '../../components/ui/Registration';
import Title from '../../components/ui/Title';
import Ellipse from '../../components/ui/Ellipses';
import Container from '../../components/ui/Container';
import styles from './styles.module.scss';
import { IParticipant, IParticipantsResponse } from '../../types/entity/Participant';
import { getWinners } from '../../utils/provider/site/participant';

const WinnersList: FC = (): JSX.Element => {
  const [winners, setWinners] = useState<IParticipant[]>([]);

  useEffect(() => {
    if (winners.length === 0) {
      getWinners<IParticipantsResponse>()
        .then(({ data }) => setWinners(data))
        .catch((error) => console.log('@WinnersList', error));
    }
  }, []);

  return (
    <Container>
      <Registration>
        <Title>Список победителей</Title>
      </Registration>
      <div className={styles.winnersList}>
        {winners.map((winner, index) => (
          <div key={index}>{`${winner.id}. ${winner.name}`}</div>
        ))}
      </div>

      <Ellipse />
    </Container>
  );
};

export default WinnersList;
