import React, { FC, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

const Header: FC = (): JSX.Element => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  // Открытие/закрытие меню бургера
  const onBurgerClickHandler = (): void => {
    setIsOpened(!isOpened);
  };

  // Закрытие меню бургера
  const closeBurgerMenu = (): void => {
    setIsOpened(false);
  };

  useOnClickOutside(menuRef, closeBurgerMenu);

  return (
    <header className={styles.header}>
      <div ref={menuRef}>
        <div className={styles.header__burger} onClick={onBurgerClickHandler}>
          <span />
          <span />
          <span />
        </div>
        <div
          className={classNames(styles.nav, {
            [styles.nav_show]: isOpened,
          })}
        >
          <a className={styles.nav__link} onClick={() => navigate('/members')}>
            Участники фронт
          </a>
          <a className={styles.nav__link} onClick={() => navigate('/winners')}>
            Победители фронт
          </a>
          <a className={styles.nav__link} onClick={() => navigate('/admin/members')}>
            Участники
          </a>
          <a className={styles.nav__link} onClick={() => navigate('/admin/winners')}>
            Победители
          </a>
          <a className={styles.nav__link} onClick={() => navigate('/admin/settings')}>
            Настройки
          </a>
        </div>
      </div>
      <a onClick={() => navigate('/')}>Вход</a>
    </header>
  );
};

export default Header;
