import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

const Title: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <h2 className={styles.title}>
      {children}
    </h2>
  );
};

export default Title;
