import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IMultipleInputProps } from './IMultipleInput';

const MultipleInput: FC<IMultipleInputProps> = ({
  refElement,
  id,
  label,
  value,
  error,
  onChangeValidation
}): JSX.Element => {
  const [inputError, setInputError] = useState<string>('');

  // Валидация при вводе
  const onChangeHandler = (): void => {
    if (typeof onChangeValidation === 'function') {
      const currentValue = (refElement.current !== null) ? refElement.current.value : '';
      const errorString = onChangeValidation(currentValue);

      if (errorString.length > 0) setInputError(errorString);
      else setInputError('');
    }
  };

  useEffect(() => {
    if (error.length > 0) setInputError(error);
  }, [error]);

  return (
    <div className={styles.input}>
      <div className={styles.input__group}>
        <label htmlFor={id} className={styles.input__label}>
          {label}
        </label>
        <textarea
          ref={refElement}
          onChange={onChangeHandler}
          defaultValue={value}
          className={styles.input__item}
        />
      </div>
      {(inputError.length > 0) && <small className={styles.input__error}>{inputError}</small>}
    </div>
  );
};
export default MultipleInput;
