import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ISelectInputProps } from './ISelectInput';

const SelectInput: FC<ISelectInputProps> = ({
  refElement,
  id,
  label,
  options,
  value,
  error,
  onChangeValidation,
  placeholder,
}): JSX.Element => {
  const [inputError, setInputError] = useState<string>('');

  // Валидация при вводе
  const onChangeHandler = (): void => {
    if (typeof onChangeValidation === 'function') {
      const currentValue = refElement.current ? refElement.current.value : '';
      const errorString = onChangeValidation(currentValue);

      if (errorString) setInputError(errorString);
      else setInputError('');
    }
  };

  useEffect(() => {
    if (error) setInputError(error);
  }, [error]);

  return (
    <div className={styles['input']}>
      <div className={styles['input__group']}>
        <label htmlFor={id} className={styles['input__label']}>
          {label}
        </label>
        <select
          ref={refElement}
          id={id}
          className={styles['input__item']}
          onChange={onChangeHandler}
          placeholder={placeholder}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value} selected={option.value === value}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
      {inputError && <small className={styles['input__error']}>{inputError}</small>}
    </div>
  );
};
export default SelectInput;
