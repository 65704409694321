import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IEditorInputProps } from './IEditorInput';
import ReactQuill, { Quill } from 'react-quill';

const EditorInput: FC<IEditorInputProps> = ({
  refElement,
  id,
  label,
  value,
  error,
  onChangeValidation,
}): JSX.Element => {
  const [inputError, setInputError] = useState<string>('');

  const Size = Quill.import('attributors/style/size');

  Size.whitelist = ['10px', '15px', '25px', '30px', '35px', '40px', '45px', '50px', '55px', '60px', '65px'];
  Quill.register(Size, true);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['10px', '15px', '25px', '30px', '35px', '40px', '45px', '50px', '55px', '60px', '65px'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link', 'image', 'video'], // link and image, video
    ],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  // Валидация при вводе
  const onChangeHandler = (changeValue: string): void => {
    if (typeof onChangeValidation === 'function') {
      const errorString = onChangeValidation(changeValue);

      if (errorString.length > 0) setInputError(errorString);
      else setInputError('');
    }
  };

  useEffect(() => {
    if (error.length > 0) setInputError(error);
  }, [error]);

  return (
    <div className={styles.input}>
      <div className={styles.input__group}>
        <label htmlFor={id} className={styles.input__label}>
          {label}
        </label>
        <ReactQuill
          ref={refElement}
          modules={modules}
          formats={formats}
          theme='snow'
          onChange={onChangeHandler}
          value={value}
          className={styles.input__editor}
        />
      </div>
      {inputError.length > 0 && <small className={styles.input__error}>{inputError}</small>}
    </div>
  );
};
export default EditorInput;
