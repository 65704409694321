import React, { FC, useContext, useEffect, useState } from 'react';
import Container from '../../components/ui/Container';
import Reg from '../../components/ui/Registration';
import Title from '../../components/ui/Title';
import Button from '../../components/ui/Buttons';
import Ellipse from '../../components/ui/Ellipses';
import Description from '../../components/ui/Description';
import { IParticipant, IParticipantsResponse } from '../../types/entity/Participant';
import styles from './styles.module.scss';
import { getParticipants } from '../../utils/provider/site/participant';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/UserContext';
import { UserContextType } from '../../context/user/IUser';

const Members: FC = (): JSX.Element => {
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const { getUser } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate();
  const user = getUser();

  const onClickHandler = (): void => {
    navigate('/choice');
  };

  useEffect(() => {
    if (participants.length === 0) {
      getParticipants<IParticipantsResponse>()
        .then(({ data }) => setParticipants(data))
        .catch((error) => console.log('@Members', error));
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      getParticipants<IParticipantsResponse>()
        .then(({ data }) => setParticipants(data))
        .catch((error) => console.log('@Members', error));
    }, 5000);
  }, []);

  return (
    <section>
      <Container>
        <Reg>
          <Title>Участники розыгрыша</Title>
          {participants.length > 0 ? (
            <>
              <div className={styles['participants']}>
                {participants.map((participant) => (
                  <div>{`${participant.id}. ${participant.name}`}</div>
                ))}
              </div>
              {user?.role === 'admin' && (
                <div className={styles['participants__button']}>
                  <Button nom='list' clickAction={onClickHandler}>
                    Выбрать победителя
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Description>Пока нет ни одного участника</Description>
          )}
        </Reg>
      </Container>
      <Ellipse />
    </section>
  );
};

export default Members;
